import { createContext } from "react"

import { EActiveSocialPlatformStatus } from "../@types/social_platform_types"
import type { TSchedulerSocialPlatformFields } from "../components/snippets/PostSchedulerModal"
import type { IPlatformSubscriptionModalRef } from "../components/subscriptions/PlatformSubscriptionModal"
import { TGetCreatorInfoResponse } from "../pages/api/v1/social-platform/creator"
import type { TSocialPlatformSchedulesResponse } from "../pages/api/v1/social-platform/schedules"
import type { BaseSnippetTask } from "../server/dao/snippet_task_dao"
import type { BaseSnippetVideo } from "../server/dao/snippet_video_dao"
import { BaseUser } from "../server/dao/user_dao"
import { functionNoop } from "./function_util"
import { TPostScheduleFormFields } from "./social_util"

export const PlatformSubscriptionModalContext =
    createContext<React.RefObject<IPlatformSubscriptionModalRef> | null>(null)

export const CurrentUserContext =
    createContext<BaseUser | null>(null)

export type TSocialPlatformParams = {
    expanded: boolean
    active: boolean
    status: EActiveSocialPlatformStatus
}

interface IInvalidSocialPlatformStatusContext extends TSocialPlatformParams {
    ready: false
    form: null
}

export interface IValidSocialPlatformStatusContext extends TSocialPlatformParams {
    ready: true
    form: TPostScheduleFormFields
}

export type TSocialPlatformStatusContext =
    | IValidSocialPlatformStatusContext
    | IInvalidSocialPlatformStatusContext

interface IPostSchedulerModalContextProps {
    context: {
        userId: string
        snippetId: BaseSnippetVideo["id"]
        taskId: BaseSnippetTask["id"]
        clipVideoKey?: string
        hashtag?: null | string
        schedulerModalContent: TSchedulerSocialPlatformFields
        creatorInfo: TGetCreatorInfoResponse
        schedules: TSocialPlatformSchedulesResponse | null
    } | null
    setContext: (context: IPostSchedulerModalContext | null) => void
}

export type IPostSchedulerModalContext = IPostSchedulerModalContextProps["context"]

export const PostSchedulerContentContext = createContext<IPostSchedulerModalContextProps>({
    context: null,
    setContext: functionNoop,
})
