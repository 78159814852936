import routes from "../configs/routes"
import {
    IGetPrepareEmailVerificationBody,
    IGetPrepareEmailVerificationResponse,
} from "../pages/api/v1/auth/email/prepare"
import {
    PostVerifyUserEmailBody,
    PostVerifyUserEmailResponse,
} from "../pages/api/v1/auth/email/verify"
import { PostLoginBody, PostLoginResponse } from "../pages/api/v1/auth/login"
import { PostRegisterBody, PostRegisterResponse } from "../pages/api/v1/auth/register"
import {
    PostRegisterExistingUserBody,
    PostRegisterExistingUserResponse,
} from "../pages/api/v1/auth/register-existing-user"
import {
    PostRegisterFirebaseBody,
    PostRegisterFirebaseResponse,
} from "../pages/api/v1/auth/register-firebase"
import {
    PostSendMagicClaimBody,
    PostSendMagicClaimResponse,
} from "../pages/api/v1/auth/send-magic-claim"
import { GetUserAuthStatusQuery, GetUserAuthStatusResponse } from "../pages/api/v1/auth/status"
import {
    PostValidateMagicClaimTokenBody,
    PostValidateMagicClaimTokenResponse,
} from "../pages/api/v1/auth/validate-magic-claim-token"
import { handleRequest } from "../utils/network_util"

const authService = (hr = handleRequest) => ({
    postRegister: (body: PostRegisterBody): Promise<PostRegisterResponse> =>
        hr(routes.POST_AUTH_REGISTER, body),

    postLogin: (body: PostLoginBody): Promise<PostLoginResponse> =>
        hr(routes.POST_AUTH_LOGIN, body),

    postLogout: () => hr(routes.POST_AUTH_LOGOUT),

    postRegisterFirebase: (body: PostRegisterFirebaseBody): Promise<PostRegisterFirebaseResponse> =>
        hr(routes.POST_AUTH_REGISTER_FIREBASE, body),

    postRegisterExistingUser: (
        body: PostRegisterExistingUserBody,
    ): Promise<PostRegisterExistingUserResponse> => hr(routes.POST_AUTH_REGISTER_EXISTING, body),

    postValidateMagicClaimToken: (
        body: PostValidateMagicClaimTokenBody,
    ): Promise<PostValidateMagicClaimTokenResponse> =>
        hr(routes.POST_VALIDATE_MAGIC_CLAIM_TOKEN, body),

    postPrepareEmailVerification: (
        body: IGetPrepareEmailVerificationBody,
    ): Promise<IGetPrepareEmailVerificationResponse> =>
        hr(routes.POST_PREPARE_EMAIL_VERIFICATION, body),

    getUserAuthStatus: (query: GetUserAuthStatusQuery): Promise<GetUserAuthStatusResponse> =>
        hr(routes.GET_USER_AUTH_STATUS, query),

    postAuthVerifyEmail: (body: PostVerifyUserEmailBody): Promise<PostVerifyUserEmailResponse> =>
        hr(routes.POST_AUTH_VERIFY_EMAIL, body),
    postSendMagicAuthClaim: (body: PostSendMagicClaimBody): Promise<PostSendMagicClaimResponse> =>
        hr(routes.POST_SEND_MAGIC_CLAIM, body),
})

export default authService
