import { Button, Heading, ModalBody, ModalFooter, ModalHeader, Text } from "@chakra-ui/react"
import { useImperativeHandle, useState } from "react"
import useAsyncEffect from "use-async-effect"

import { AE } from "../../@types/analytics"
import { EPlatformSubscriptionPlan } from "../../@types/subscription_types"
import _c from "../../configs/constants"
import Analytics from "../../controllers/analytics_controller"
import { BaseUser } from "../../server/dao/user_dao"
import api from "../../services/root_service"
import { getUserSubscriptionVersion } from "../../utils/subscription_util"
import Modal from "../Modal"
import PricingSection from "../PricingSection"
import { IPricingCardProps } from "./PricingCard"

export enum EFeaturePaywall {
    VideoAspectRatio = "VideoAspectRatio",
    BrandsCount = "BrandsCount",
    AccessEditor = "AccessEditor",
}

const paywallFeaturesModel = {
    [EFeaturePaywall.VideoAspectRatio]: {
        title: "Upgrade to change aspect ratio",
        description: [
            "Pro subscription users can change the aspect ratio of their videos to Landscape or Square, in addition to Portrait.",
        ],
    },
    [EFeaturePaywall.BrandsCount]: {
        title: "Upgrade to add more brands",
        description: ["With a subscription you can have multiple brands."],
    },
    [EFeaturePaywall.AccessEditor]: {
        title: "Upgrade to edit clips",
        description: [
            "Subscribed users can edit their clips in the video editor.",
            "You can trim, rearrange, and style your clips to create the perfect video.",
        ],
    },
}

type TPaywallFeatureArgs = {
    feature: EFeaturePaywall
    title?: string
    description?: string[]
}

export interface IPlatformSubscriptionModalProps {
    currentUser?: BaseUser
    modalRef: React.Ref<IPlatformSubscriptionModalRef>
}

export interface IPlatformSubscriptionModalRef {
    open: () => void
    close: () => void
    showPaywall: (args: TPaywallFeatureArgs) => void
}

const PlatformSubscriptionModal = ({ currentUser, modalRef }: IPlatformSubscriptionModalProps) => {
    const [isVisible, setIsVisible] = useState(false)
    const [paywall, setPaywall] = useState<TPaywallFeatureArgs | null>()

    const [plans, setPlans] = useState<IPricingCardProps["plans"]>([])

    const showPaywall = !!paywall

    const handleCloseModal = () => {
        setIsVisible(false)
        setPaywall(null)
        Analytics.trackEvent(AE.AISnippet_ClosePaywallModal)
    }

    const handleOpenModal = () => {
        setIsVisible(true)
    }

    const handleOpenPaywall = (args: TPaywallFeatureArgs) => {
        setPaywall(args)
        setIsVisible(true)
    }

    useImperativeHandle(
        modalRef,
        () => ({
            open: handleOpenModal,
            showPaywall: handleOpenPaywall,
            close: handleCloseModal,
        }),
        [],
    )

    useAsyncEffect(async (isMounted) => {
        if (!isMounted()) {
            return
        }
        const results = await api().getLLSubscriptionPlans({
            planType: EPlatformSubscriptionPlan.Snippets,
            version: getUserSubscriptionVersion(currentUser),
        })
        setPlans(results.plans)
    }, [])

    const renderPlans = () => {
        const title = (() => {
            if (!currentUser?.platformSubscriptions.length) {
                return `Choose your plan`
            } else if (!!currentUser?.platformSubscriptions.length) {
                return "Subscribe to a better plan for you"
            } else {
                return "Discover your perfect plan"
            }
        })()
        return (
            <>
                <ModalHeader marginTop={8}>
                    <Heading as="h1" fontSize="3xl" fontWeight="bold">
                        {title}
                        <Text
                            as="span"
                            display="block"
                            fontWeight="400"
                            fontSize="16px"
                            paddingTop="10px"
                        >
                            {`We've simplified pricing to help you find your
                    ideal fit`}
                        </Text>
                    </Heading>
                </ModalHeader>
                <ModalBody paddingX={["20px", null, "25px"]} paddingBottom={["20px", null, "25px"]}>
                    <PricingSection plans={plans} currentUser={currentUser} />
                </ModalBody>
            </>
        )
    }

    const renderPaywall = () => {
        const feature = paywallFeaturesModel[paywall!.feature]
        const options: { text: string; isPrimary: boolean; onClick: () => void }[] = []
        const title = paywall!.title ?? feature.title
        const description =
            !!paywall!.description && paywall!.description.length > 0
                ? paywall!.description
                : feature.description
        if (paywall!.feature === EFeaturePaywall.VideoAspectRatio) {
            options.push({
                text: "See all PRO features",
                onClick: () => setPaywall(null),
                isPrimary: true,
            })
        } else {
            options.push({
                text: "See all PRO features",
                onClick: () => setPaywall(null),
                isPrimary: true,
            })
        }
        return (
            <>
                <ModalHeader marginTop={2} paddingBottom={0}>
                    <Heading as="h1" fontSize="xl" fontWeight="700">
                        {title}
                    </Heading>
                </ModalHeader>
                <ModalBody>
                    {description.map((line, index) => (
                        <Text key={index} marginBottom="0.6rem">
                            {line}
                        </Text>
                    ))}
                </ModalBody>
                <ModalFooter paddingTop={0}>
                    {options.map((option, index) => (
                        <Button
                            key={index}
                            onClick={option.onClick}
                            variant={option.isPrimary ? "primaryGreenBlack" : "muted"}
                            size="lg"
                            width="100%"
                            boxShadow="lg"
                        >
                            {option.text}
                        </Button>
                    ))}
                </ModalFooter>
            </>
        )
    }

    return (
        <Modal
            size={showPaywall ? "sm" : "6xl"}
            onDismiss={handleCloseModal}
            isOpen={isVisible}
            canDismiss
            blockScrollOnMount={true}
            overlayStyles={{
                background: "blackAlpha.300",
                backdropFilter: "blur(1px) grayscale(0.7)",
            }}
        >
            {showPaywall && renderPaywall()}
            {!showPaywall && renderPlans()}
        </Modal>
    )
}

export default PlatformSubscriptionModal
