// --------------------------------------------------------
// Types
// --------------------------------------------------------

import { ESocialPlatformErrorText, ESocialPlatformErrorType } from "../@types/social_platform_types"
import { ERequestType } from "../configs/routes"

export enum EError {
    APIResponseError = "APIResponseError",
    SignatureVerificationError = "SignatureVerificationError",
    ResponseParseError = "ResponseParseError",
}

export type TApiResponseError = {
    name: EError
    json?: Record<string, any>
    statusCode?: number
    url?: string
}

export enum EAuthErrorCode {
    InvalidActionCode = "invalid-action-code",
    FailedToFetchUser = "failed-to-fetch-user",
    TooManyRequests = "too-many-requests",
    ExpiredActionCode = "expired-action-code",
    AlreadyVerified = "already-verified",
    AlreadyRequested = "already-requested",
    InvalidOobToken = "invalid-oob-token",
    UncaughtError = "uncaught-error",
    SkipVerification = "skip-verification",
}

// --------------------------------------------------------
// Errors
// --------------------------------------------------------

export class APIResponseError extends Error {
    public static withResponse = (
        json: Record<string, any>,
        statusCode?: number,
        request?: {
            url: string
            method: ERequestType
            payload?: Record<string, any>
        },
    ): TApiResponseError => {
        const error = new APIResponseError()
        error.json = json
        error.request = request
        error.statusCode = statusCode
        error.message = json.message
            ? json.message ?? json.error
            : json.errors.map(({ message }: any) => `${message}`).join(". ")
        return error
    }
    public name = EError.APIResponseError
    public json?: Record<string, any>
    public statusCode?: number
    public request?: {
        url: string
        method: ERequestType
        payload?: Record<string, any>
    }
}

export class ResponseParseError extends Error {
    public static withResponse = (
        json: Record<string, any>,
        statusCode?: number,
        request?: {
            url: string
            method: ERequestType
            payload?: Record<string, any>
        },
    ) => {
        const error = new ResponseParseError()
        error.json = json
        error.request = request
        error.statusCode = statusCode
        error.message = json.errors
            ? json.errors.map(({ message }: any) => `${message}`).join(". ")
            : json.error
        return error
    }
    public name = EError.ResponseParseError
    public json?: Record<string, any>
    public statusCode?: number
    public request?: {
        url: string
        method: ERequestType
        payload?: Record<string, any>
    }
}

export class SignatureVerificationError extends Error {
    public name = EError.SignatureVerificationError
}

type IpApiResponseFormat = {
    ip: string // "140.228.44.79",
    network: string // "140.228.32.0/19",
    version: string // "IPv4",
    city: string // "London",
    region: string // "England",
    region_code: string // "ENG",
    country: string // "GB",
    country_name: string // "United Kingdom",
    country_code: string // "GB",
    country_code_iso3: string // "GBR",
    country_capital: string // "London",
    country_tld: string // ".uk",
    continent_code: string // "EU",
    in_eu: boolean // false,
    postal: string // "EC2V 8AS",
    latitude: number // 51.515158,
    longitude: number // -0.095445,
    timezone: string // "Europe/London",
    utc_offset: string // "+0000",
    country_calling_code: string // "+44",
    currency: string // "GBP",
    currency_name: string // "Pound",
    languages: string // "en-GB,cy-GB,gd",
    country_area: number // 244820.0,
    country_population: number // 66488991,
    asn: string // "AS56478",
    org: string // "Hyperoptic Ltd"
}

async function getIpInfo() {
    try {
        const response = await fetch("https://ipapi.co/json/")
        const data = await response.json()
        return data as IpApiResponseFormat
    } catch (error) {
        return undefined
    }
}

export const getPageDebugInfo = async () => {
    const url = window.location.href
    const timestamp = new Date().toISOString()
    const windowSize = `${window.innerWidth}x${window.innerHeight}`
    const ipInfo = await getIpInfo()

    return {
        url,
        timestamp,
        windowSize,
        country: ipInfo?.country_name,
        city: ipInfo?.city,
        timezone: ipInfo?.timezone,
        ipAddress: ipInfo?.ip,
        userAgent: window.navigator.userAgent,
    }
}

export const getSocialPlatformErrorText = (errorType: ESocialPlatformErrorType) =>
    ESocialPlatformErrorText[errorType]

export class SocialPlatformError extends Error {
    type: string
    code?: number
    error_subcode?: number
    log_id?: string
    constructor(
        type: string,
        message: string,
        code?: number,
        error_subcode?: number,
        log_id?: string,
    ) {
        super(message)
        this.type = type
        this.code = code
        this.error_subcode = error_subcode
        this.log_id = log_id
    }
}

export const getAuthEmailErrorText = (code: EAuthErrorCode) => {
    if (code === EAuthErrorCode.InvalidActionCode) {
        return { message: "Your code seems invalid.", resendEnabled: true }
    }

    if (code === EAuthErrorCode.ExpiredActionCode) {
        return { message: "Your code has expired.", resendEnabled: true }
    }

    if (code === EAuthErrorCode.AlreadyVerified) {
        return {
            message: "This email has already been verified.",
            resendEnabled: false,
        }
    }

    return {
        message: "An error occurred while verifying your email.",
        resendEnabled: true,
    }
}
