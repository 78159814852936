import dayjs from "dayjs"

import { VALID_TIMEZONE_CODES } from "./timezone_util"

type Validator<T> = {
    message: string
    value: T
}

type FormValue = string | number | boolean | Date | File | Record<any, any> | undefined | null

interface ValidatorConfig<T extends Record<string, FormValue>> {
    data: T
    validation: FormFieldValidator<T>
}

export type FormFieldValidator<T> = Partial<Record<keyof T, Validation>>
export type ValidationError<T> = Partial<Record<keyof T, string>>

export interface Validation {
    required?: Validator<boolean> | Validator<(...args: any[]) => boolean>
    minLength?: Validator<number>
    maxLength?: Validator<number>
    date?: {
        future?: Validator<boolean>
    }
    custom?: Validator<(input?: any) => boolean>
    email?: Validator<boolean>
}

export interface ValidationResult<T> {
    isValid: boolean
    errors?: ValidationError<T>
    errorMessageList?: { [message: string]: string }[]
}

export const validateFields = <T extends Record<string, FormValue>>({
    data,
    validation,
}: ValidatorConfig<T>): ValidationResult<T> => {
    if (!validation) {
        return { isValid: true }
    }

    let valid = true
    const errors: ValidationError<T> = {}

    for (const key in validation) {
        const value = data[key]
        const validator = validation[key]

        // validate required field
        if (
            !value &&
            validator?.required?.value &&
            ((typeof validator?.required?.value === "function" &&
                !!validator?.required?.value(data)) ||
                (typeof validator?.required?.value === "boolean" &&
                    validator?.required?.value === true))
        ) {
            errors[key] = validator.required.message
            valid = false
            continue
        }

        // validate min length
        if (
            typeof value === "string" &&
            validator?.minLength &&
            value.trim().length < validator.minLength.value
        ) {
            errors[key] = validator.minLength.message
            valid = false
            continue
        }

        // validate max length
        if (
            typeof value === "string" &&
            validator?.maxLength &&
            value.trim().length > validator.maxLength.value
        ) {
            errors[key] = validator.maxLength.message
            valid = false
            continue
        }

        // validate dates
        if (
            typeof value === "string" &&
            validator?.date &&
            validator.date.future &&
            dayjs(value).toDate() < dayjs().startOf("day").toDate()
        ) {
            errors[key] = validator.date.future.message
            valid = false
            continue
        }

        // validate email
        if (typeof value === "string" && validator?.email && !validateEmail(value)) {
            errors[key] = validator.email.message
            valid = false
        }

        // validate custom
        if (validator?.custom && !validator.custom.value(value)) {
            errors[key] = validator.custom.message
            valid = false
            continue
        }
    }

    const errorMessageList = Object.values(errors).map((error: string) => ({ message: error }))

    return { isValid: valid, errors, errorMessageList }
}

export const NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/

export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const checkNull = (e: any) => e === null || e === "" || e === undefined

export const isObject = (item: any): item is Record<string, any> =>
    typeof item === "object" && item.constructor.name === "Object"

export const isNumber = (input: any): input is number => !isNaN(input)

export const validateName = (e: any) => {
    if (checkNull(e)) {
        return false
    }
    const firstname = e.split(" ").slice(0, -1).join(" ")
    const surname = e.split(" ").slice(-1).join(" ")
    return firstname && validateSingleName(firstname) && surname && validateSingleName(surname)
}

export const validateSingleName = (e: any) => {
    if (checkNull(e)) {
        return false
    }
    return e && e.length > 1 && NAME_REGEX.test(e)
}

export const validateEmail = (e: any) => {
    if (checkNull(e)) {
        return false
    }
    return EMAIL_REGEX.test(e)
}

export const validateDob = (e: any) => {
    return dayjs(e).isValid() && dayjs(e).isBefore(dayjs(new Date()))
}

export const validatePassword = (e: any) => {
    if (checkNull(e)) {
        return false
    }
    return e.length > 5
}

export const isNotNull = (e: any) => !checkNull(e)

const validISO31661Alpha2CountriesCodes = [
    "AD",
    "AE",
    "AF",
    "AG",
    "AI",
    "AL",
    "AM",
    "AO",
    "AQ",
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AX",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BI",
    "BJ",
    "BL",
    "BM",
    "BN",
    "BO",
    "BQ",
    "BR",
    "BS",
    "BT",
    "BV",
    "BW",
    "BY",
    "BZ",
    "CA",
    "CC",
    "CD",
    "CF",
    "CG",
    "CH",
    "CI",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CU",
    "CV",
    "CW",
    "CX",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "EH",
    "ER",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FK",
    "FM",
    "FO",
    "FR",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GG",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GS",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HM",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IO",
    "IQ",
    "IR",
    "IS",
    "IT",
    "JE",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KI",
    "KM",
    "KN",
    "KP",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LB",
    "LC",
    "LI",
    "LK",
    "LR",
    "LS",
    "LT",
    "LU",
    "LV",
    "LY",
    "MA",
    "MC",
    "MD",
    "ME",
    "MF",
    "MG",
    "MH",
    "MK",
    "ML",
    "MM",
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NF",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PK",
    "PL",
    "PM",
    "PN",
    "PR",
    "PS",
    "PT",
    "PW",
    "PY",
    "QA",
    "RE",
    "RO",
    "RS",
    "RU",
    "RW",
    "SA",
    "SB",
    "SC",
    "SD",
    "SE",
    "SG",
    "SH",
    "SI",
    "SJ",
    "SK",
    "SL",
    "SM",
    "SN",
    "SO",
    "SR",
    "SS",
    "ST",
    "SV",
    "SX",
    "SY",
    "SZ",
    "TC",
    "TD",
    "TF",
    "TG",
    "TH",
    "TJ",
    "TK",
    "TL",
    "TM",
    "TN",
    "TO",
    "TR",
    "TT",
    "TV",
    "TW",
    "TZ",
    "UA",
    "UG",
    "UM",
    "US",
    "UY",
    "UZ",
    "VA",
    "VC",
    "VE",
    "VG",
    "VI",
    "VN",
    "VU",
    "WF",
    "WS",
    "YE",
    "YT",
    "ZA",
    "ZM",
    "ZW",
]

export const validateCountryCode = (code: string) =>
    code.length === 2 && validISO31661Alpha2CountriesCodes.includes(code.toUpperCase())

export const validateTimezone = (timezone: string) => VALID_TIMEZONE_CODES.includes(timezone)

export const isJson = (str: any): str is string => {
    try {
        if (!str) {
            return false
        }
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export const testRegexes = (regexes: RegExp[], str: string) => {
    return regexes.every((regex) => regex.test(str))
}