import { Box, Button, Flex, Heading, Text, VStack } from "@chakra-ui/react"
import { PlatformSubscriptionPlan } from "@prisma/client"
import dayjs from "dayjs"
import { Fragment, useMemo, useState } from "react"

import {
    EFeatureAccessLevel,
    EPlatformSubscriptionPlanFrequency,
    EPlatformSubscriptionType,
    ESubscriptionOption,
} from "../../@types/subscription_types"
import { BaseUser } from "../../server/dao/user_dao"
import { formatPrice } from "../../utils/currency_util"
import { getFeatureAccessLevel } from "../../utils/subscription_util"
import OptionedSlider from "../OptionedSlider"

const PROCESSING_MINUTES_KEY = "video upload minutes"

const PRICING_PLAN_LOOKUP = {
    [EFeatureAccessLevel.Free]: {
        description: "Everything you need to get started with creating AI clips.",
        benefits: [
            {
                unit: "30",
                description: "video upload minutes",
            },
            {
                description: "Downloads unavailable",
            },
            {
                description: "Limited storage",
            },
            {
                unit: "1",
                description: "brand template",
            },
            {
                unit: "3 days",
                description: "video history",
            },
            {
                unit: "30m",
                description: "video duration limit",
            },
        ],
    },
    [EFeatureAccessLevel.Starter]: {
        description: "Everything you need to get started with creating AI clips.",
        benefits: [
            {
                unit: "100",
                description: "video upload minutes",
            },
            {
                unit: "720p",
                description: "downloads",
            },
            {
                unit: "5GB",
                description: "storage",
            },
            {
                unit: "5",
                description: "brand templates",
            },
            {
                unit: "7 days",
                description: "video history",
            },
            {
                unit: "45m",
                description: "video duration limit",
            },
        ],
    },
    [EFeatureAccessLevel.Pro]: {
        description: "For the more established creator who posts quality videos regularly.",
        benefits: [
            {
                unit: "500",
                description: PROCESSING_MINUTES_KEY,
            },
            {
                unit: "1080p",
                description: "downloads",
            },
            {
                unit: "500GB",
                description: "storage",
            },
            {
                unit: "Unlimited",
                description: "brand templates",
            },
            {
                unit: "30 days",
                description: "video history",
            },
            {
                unit: "120m",
                description: "video duration limit",
            },
        ],
    },
}

export const generatePlanDataForCards = (
    plans: (Omit<PlatformSubscriptionPlan, "totalStorage"> & { totalStorage: number })[],
) => {
    return [
        {
            accessLevel: EFeatureAccessLevel.Free,
            interval: EPlatformSubscriptionPlanFrequency.Month,
        },
        {
            accessLevel: EFeatureAccessLevel.Free,
            interval: EPlatformSubscriptionPlanFrequency.Year,
        },
        ...plans.map((plan) => ({
            ...plan,
            accessLevel: getFeatureAccessLevel(plan.name as EPlatformSubscriptionType),
        })),
    ]
}

// Way too much logic inside Pricing card component so I moved it out for easier testing in future
const generatePricingCardData = (args: {
    plans: ((NonNullable<PlatformSubscriptionPlan> | Record<string, any>) & {
        accessLevel: EFeatureAccessLevel
    })[]
    selectedPlanId: string
    currentUser?: BaseUser
    isYearly: boolean
}) => {
    const { plans, selectedPlanId, isYearly: isYearlyView, currentUser } = args
    const plan = plans.find((p) => p.id === selectedPlanId)!
    const isAnnualPlan = plan.interval === EPlatformSubscriptionPlanFrequency.Year
    const isHighlighted = plans[0].accessLevel === EFeatureAccessLevel.Pro
    const model = (() => {
        const planModel = PRICING_PLAN_LOOKUP[plan.accessLevel]
        if (plan.accessLevel !== EFeatureAccessLevel.Pro) {
            return planModel
        }
        return {
            ...planModel,
            benefits: planModel.benefits.map((benefit) => {
                if (benefit?.description === PROCESSING_MINUTES_KEY) {
                    return {
                        ...benefit,
                        unit: `${plan.totalMinutes}`,
                    }
                }
                return benefit
            }),
        }
    })()
    const higherPrice = (() => {
        if (!isAnnualPlan || !isHighlighted) {
            return null
        }
        const originalPrice = (plan.totalInLowestDenom / 12) * 2
        return formatPrice(
            {
                currency: plan.currency,
                totalInLowestDenom: originalPrice,
            },
            true,
        )
    })()
    const planPrice = formatPrice(
        {
            currency: plan.currency,
            totalInLowestDenom: isAnnualPlan ? plan.totalInLowestDenom / 12 : plan.totalInLowestDenom,
        },
        true,
    )

    const activeSubscription = (() => {
        const hasSubscription = !!currentUser && !!currentUser.platformSubscriptions.length
        if (hasSubscription) {
            const activeSubs = currentUser.platformSubscriptions.filter(
                (sub) => sub.status === "active" || sub.status === "trialing",
            )
            return activeSubs[0]
        }
        return null
    })()
    const isCurrentPlanSubscribed = (() => {
        if (activeSubscription) {
            return (
                plan.name === activeSubscription.plan.name &&
                plan.interval == activeSubscription.interval
            )
        }
        return false
    })()
    const hasScheduledDowngrade =
        !!activeSubscription?.futurePlanId && activeSubscription.futurePlanId === plan.id
    const subheadingText = (() => {
        if (!isAnnualPlan) {
            if (isYearlyView && plan.accessLevel === EFeatureAccessLevel.Starter) {
                return "Available as monthly only"
            }
            return null
        }
        let priceStr
        if (plan.accessLevel === EFeatureAccessLevel.Free) {
            priceStr = "Free forever"
        } else if (plan.accessLevel === EFeatureAccessLevel.Starter) {
            priceStr = "Available as monthly only"
        } else {
            const annualPrice = formatPrice(
                {
                    currency: plan.currency,
                    totalInLowestDenom: plan.totalInLowestDenom,
                },
                true,
            )
            priceStr = `${annualPrice} billed yearly`
        }
        return priceStr
    })()
    const primaryButton = (() => {
        let variant = "muted"
        let buttonText: string
        let buttonAction: ESubscriptionOption
        let isButtonDisabled = hasScheduledDowngrade
        let hideButton = false

        const hasSubscription = !!activeSubscription
        const isLoggedIn = !!currentUser

        if (isCurrentPlanSubscribed) {
            variant = "outline"
            buttonText = "Current plan"
            buttonAction = ESubscriptionOption.None
            isButtonDisabled = true
        } else if (hasSubscription) {
            const isSubscribedPlanYearly =
                activeSubscription.interval === EPlatformSubscriptionPlanFrequency.Year
            const currentPrice = activeSubscription?.plan.totalInLowestDenom
            const comparisonPrice = plan.totalInLowestDenom
            if (isAnnualPlan) {
                const isUpgrade =
                    (isSubscribedPlanYearly && currentPrice < comparisonPrice) ||
                    !isSubscribedPlanYearly
                const isDowngrade = isSubscribedPlanYearly && currentPrice > comparisonPrice
                if (isUpgrade) {
                    buttonText = "Upgrade"
                    buttonAction = ESubscriptionOption.Upgrade
                    variant = "primaryGreenBlack"
                } else if (isDowngrade) {
                    buttonText = hasScheduledDowngrade ? "Scheduled downgrade" : "Downgrade"
                    buttonAction = ESubscriptionOption.Downgrade
                    variant = "muted"
                } else {
                    buttonText = "Switch to monthly"
                    variant = "muted"
                    buttonAction = ESubscriptionOption.Downgrade
                }
            } else {
                const isUpgrade = !isSubscribedPlanYearly && currentPrice < comparisonPrice
                const isDowngrade =
                    (!isSubscribedPlanYearly && currentPrice > comparisonPrice) ||
                    isSubscribedPlanYearly
                if (isUpgrade) {
                    buttonText = "Upgrade"
                    buttonAction = ESubscriptionOption.Upgrade
                    variant = "primaryGreenBlack"
                } else if (isDowngrade) {
                    buttonText = hasScheduledDowngrade ? "Scheduled downgrade" : "Downgrade"
                    buttonAction = ESubscriptionOption.Downgrade
                    variant = "muted"
                } else {
                    buttonText = "Switch to yearly"
                    variant = "primaryGreenBlack"
                    buttonAction = ESubscriptionOption.Upgrade
                }
            }
            if (plan.accessLevel === EFeatureAccessLevel.Free) {
                hideButton = true
            }
        } else {
            buttonText =
                plan.trialPeriodDays! > 0
                    ? `Try free for ${plan.trialPeriodDays} days`
                    : "Get started"
            if (plan.accessLevel === EFeatureAccessLevel.Pro) {
                variant = "primaryGreenBlack"
            } else if (plan.accessLevel === EFeatureAccessLevel.Starter) {
                variant = "primary"
            } else {
                if (!isLoggedIn) {
                    variant = "muted"
                    buttonText = "Create an account"
                    buttonAction = ESubscriptionOption.Register
                } else {
                    variant = "outline"
                    buttonText = "Current plan"
                    buttonAction = ESubscriptionOption.None
                    isButtonDisabled = true
                }
            }
        }
        if (hideButton) {
            return null
        }
        if (isButtonDisabled) {
            return {
                text: buttonText,
                action: buttonAction!,
                isDisabled: true,
            }
        }
        return {
            variant,
            text: buttonText,
            action: buttonAction!,
            isDisabled: false,
        }
    })()
    return {
        isHighlighted,
        model,
        higherPrice,
        planPrice,
        isCurrentPlanSubscribed,
        hasScheduledDowngrade,
        subheadingText,
        benefits: model.benefits,
        primaryButton,
        downgradeDate: activeSubscription?.scheduledDowngrade,
    }
}

export interface IPricingCardProps {
    plans: ((NonNullable<PlatformSubscriptionPlan> | Record<string, any>) & {
        accessLevel: EFeatureAccessLevel
    })[]
    onClickPlan: (planId: string, action: ESubscriptionOption) => void
    currentUser?: BaseUser
    isYearly: boolean
}

const PricingCard = (props: IPricingCardProps) => {
    const { plans, isYearly, currentUser, onClickPlan } = props
    const [plan, setPlan] = useState<IPricingCardProps["plans"][number]>(plans[0])
    const {
        isHighlighted,
        higherPrice,
        planPrice,
        isCurrentPlanSubscribed,
        hasScheduledDowngrade,
        subheadingText,
        benefits,
        primaryButton,
        downgradeDate,
    } = generatePricingCardData({
        plans,
        selectedPlanId: plan.id,
        currentUser,
        isYearly
    })

    const renderPlanSubheading = () => {
        if (!subheadingText) {
            return null
        }
        return (
            <Text fontWeight={600} color="#868686">
                {subheadingText}
            </Text>
        )
    }
    const renderPrice = () => {
        const showDiscount = higherPrice !== null
        const PriceComponents = []
        if (showDiscount) {
            PriceComponents.push(
                <Text
                    as="span"
                    fontWeight="bold"
                    fontSize="36px"
                    color="#000"
                    textDecoration="line-through"
                >
                    {higherPrice}
                </Text>,
                <Text as="span" fontWeight="bold" fontSize="36px" color="#0AD69F">
                    {planPrice}
                </Text>,
                <Text as="span" fontWeight="bold" fontSize="22px" color="#868686">
                    {"/ month"}
                </Text>,
            )
        } else {
            PriceComponents.push(
                <Text as="span" fontWeight="bold" fontSize="36px" color="#000">
                    {planPrice}
                </Text>,
                <Text as="span" fontWeight="bold" fontSize="22px" color="#000">
                    {"/ month"}
                </Text>,
            )
        }
        return (
            <Flex alignItems="center" gap="5px">
                {PriceComponents.map((Component, i) => (
                    <Fragment key={`${i}_${showDiscount}`}>{Component}</Fragment>
                ))}
            </Flex>
        )
    }
    const renderSlider = () => {
        const showSlider = plan.name === EPlatformSubscriptionType.Pro
        if (!showSlider) {
            return <Box height="65px" />
        }
        const options = plans.map((p) => p.totalMinutes)
        return (
            <Box padding="20px 10px" width="full">
                <OptionedSlider
                    options={options}
                    value={plan.totalMinutes}
                    onChange={(value: number) => {
                        setPlan(plans.find((p) => p.totalMinutes === value)!)
                    }}
                />
            </Box>
        )
    }
    const renderPlanBenefits = () => {
        return (
            <VStack
                spacing="0"
                align="stretch"
                width="full"
                flex="1"
                textAlign="left"
                marginBottom="10px"
            >
                {benefits.map((benefit, i) => {
                    const hasUnit = !!benefit.unit
                    let benefitText
                    if (hasUnit) {
                        benefitText = (
                            <>
                                <strong>{benefit.unit}</strong> {benefit.description}
                            </>
                        )
                    } else {
                        benefitText = <>{benefit.description}</>
                    }
                    return (
                        <Text
                            key={benefit.description}
                            borderTop="1px solid #EAEAEA"
                            paddingY={2}
                            marginBottom={0}
                        >
                            {benefitText}
                        </Text>
                    )
                })}
            </VStack>
        )
    }
    const renderPrimaryButton = () => {
        if (!primaryButton) {
            return null
        }
        if (primaryButton.isDisabled) {
            return (
                <Box
                    display="inline-flex"
                    justifyContent="center"
                    alignItems="center"
                    width="full"
                    height="46px"
                    paddingX={6}
                    borderRadius="7px"
                    onClick={() => onClickPlan(plan.id, primaryButton.action)}
                    border="2px solid"
                    borderColor="gray.300"
                    userSelect="none"
                >
                    <Text as="span" color="gray.400" fontSize="lg" fontWeight="600">
                        {primaryButton.text}
                    </Text>
                </Box>
            )
        }
        return (
            <Button
                variant={primaryButton.variant!}
                size="lg"
                width="full"
                borderRadius="7px"
                onClick={() => onClickPlan(plan.id, primaryButton.action)}
                isDisabled={primaryButton.isDisabled}
                borderWidth={2}
            >
                <Text as="span" color="currentcolor">
                    {primaryButton.text}
                </Text>
            </Button>
        )
    }
    const renderButtonFooter = () => {
        if (isCurrentPlanSubscribed) {
            return (
                <Button
                    variant="link"
                    colorScheme="gray"
                    onClick={() => onClickPlan(plan.id, ESubscriptionOption.Cancel)}
                    paddingTop="15px"
                >
                    <Text as="span" textAlign="center" color="gray.400">
                        Cancel Plan
                    </Text>
                </Button>
            )
        }
        if (hasScheduledDowngrade) {
            const downgradeDateStr = dayjs(downgradeDate).format("Do [of] MMMM YYYY")
            return (
                <Text as="span" textAlign="center" color="#A99F9F">
                    Downgrade scheduled for the {downgradeDateStr}
                </Text>
            )
        }
        return null
    }
    return (
        <Flex
            width="full"
            boxSizing="border-box"
            direction="column"
            position="relative"
            padding={{
                base: isHighlighted ? "36px 3px 3px 3px" : "0",
                md: isHighlighted ? "36px 3px 3px 3px" : "36px 0 0 0",
            }}
            borderRadius={isHighlighted ? "25px" : undefined}
            backgroundColor={isHighlighted ? "#06D6A0" : undefined}
            minWidth={[null, null, "300px"]}
            maxWidth={[null, null, "320px"]}
        >
            {isHighlighted && (
                <Text
                    position="absolute"
                    top="8px"
                    left="calc(50% - 50px)"
                    color="white"
                    fontWeight="bold"
                    fontSize="14px"
                >
                    MOST POPULAR
                </Text>
            )}
            <VStack
                minHeight="200px"
                padding="22px"
                flex="1"
                spacing={0}
                borderRadius="22px"
                backgroundColor="white"
                textAlign="center"
                position="relative"
                zIndex="1"
                _before={
                    !isHighlighted
                        ? {
                              content: '""',
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: "linear-gradient(to bottom, #F1F1F1, #D5D5D5)",
                              zIndex: -1,
                              WebkitMask:
                                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                              mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                              WebkitMaskComposite: "xor",
                              maskComposite: "exclude",
                              borderRadius: "22px",
                              padding: "1px",
                          }
                        : undefined
                }
            >
                <Heading fontSize="16px" color="#06D6A0" textTransform="uppercase" marginBottom={0}>
                    {plan.accessLevel}
                </Heading>
                {renderPrice()}
                {renderPlanSubheading()}
                {renderSlider()}
                {renderPlanBenefits()}
                {renderPrimaryButton()}
                {renderButtonFooter()}
                <Box flex="1" />
            </VStack>
        </Flex>
    )
}

export default PricingCard
