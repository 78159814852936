import { EFeatureAccessLevel } from './../@types/subscription_types';
import { EPlatformSubscriptionType, ETrialType } from "../@types/subscription_types"
import _c from "../configs/constants"
import { BaseUser } from "../server/dao/user_dao"

export const isAIClipSubscriptionPlan = (planName: string): boolean =>
    [EPlatformSubscriptionType.Pro, EPlatformSubscriptionType.Starter].includes(
        planName as EPlatformSubscriptionType,
    )

export const getSubscriptionVersionByTrialType = (trialType: ETrialType): string => {
    if (trialType === ETrialType.ThreeDays) {
        return _c.SHORT_TRIAL_SUBSCRIPTION_VERSION
    }

    if (trialType === ETrialType.Regular) {
        return _c.CURRENT_SUBSCRIPTION_VERSION
    }

    return _c.NO_TRIAL_SUBSCRIPTION_VERSION
}

export const getUserSubscriptionVersion = (user?: BaseUser) => {
    if (!user || !user.trialType) {
        return _c.CURRENT_SUBSCRIPTION_VERSION
    }
    return getSubscriptionVersionByTrialType(user.trialType as ETrialType)
}

export const getFeatureAccessLevel = (subscriptionType: EPlatformSubscriptionType) => {
    if (subscriptionType === EPlatformSubscriptionType.Pro) {
        return EFeatureAccessLevel.Pro
    }
    return EFeatureAccessLevel.Starter
}