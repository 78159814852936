import { EVideoResolution } from "./snippet_types"
import { EActiveSocialPlatform } from "./social_platform_types"
import { EPlatformSubscriptionStatus, ESubscriptionOption } from "./subscription_types"

export enum AE {
    // Whenever a page navigation occurs
    Page_Visit = "Page - Visit",

    // Whenever an application crash is detected
    Application_Error = "Application - Error",

    // When a guest opens the login page
    Login_Start = "Login - Start",

    // When a guest submits their details for login
    Login_ClickSubmit = "Login - Click Submit",

    // When a user successfully logs in
    Login_Finish = "Login - Finish",

    // When a guest receives an error upon an attempted login
    Login_Error = "Login - Error",

    // When a guest opens the signup page
    Signup_Start = "Signup - Start",

    // When a guest submits their details for signup
    Signup_ClickSubmit = "Signup - Click Submit",

    // When a creator successfully signs up
    Signup_Finish = "Signup - Finish",

    // When a creator submits the "claim username" input on homepage
    Signup_SubmitClaimUsername = "Signup - Submit Claim Username",

    // When a user resends an email verification link
    Signup_ResendEmailVerification = "Signup - Resend Email Verification",

    // When a guest receives an error upon an attempted signup
    Signup_Error = "Signup - Error",

    // When a user updates to creator profile
    ToggleProfile_UpdateToCreator = "Toggle Profile - Update To Creator",

    // When a creator opens the create live page
    CreateLive_Start = "Create Live - Start",

    // When a creator has filled in all details + clicked submit
    CreateLive_ClickSubmit = "Create Live - Click Submit",

    // When a creator has successfully created a live
    CreateLive_Finish = "Create Live - Finish",

    // When a creator opens the create course page
    CreateCourse_Start = "Create Course - Start",

    // When a creator has filled in all details + clicked submit
    CreateCourse_ClickSubmit = "Create Course - Click Submit",

    // When a creator has successfully created a course
    CreateCourse_Finish = "Create Course - Finish",

    // When a creator opens the edit course page
    EditCourse_Start = "Edit Course - Start",

    // When a creator has filled in all details + clicked submit
    EditCourse_ClickSubmit = "Edit Course - Click Submit",

    // When a creator has successfully edited a course
    EditCourse_Finish = "Edit Course - Finish",

    // When a creator opens the edit live page
    EditLive_Start = "Edit Live - Start",

    // When a creator has filled in all details + clicked submit
    EditLive_ClickSubmit = "Edit Live - Click Submit",

    // When a creator has successfully edited a live
    EditLive_Finish = "Edit Live - Finish",

    // When a creator first clicks the delete button
    DeleteLive_Start = "Delete Live - Start",

    // When a creator deletes a live
    DeleteLive_Finish = "Delete Live - Finish",

    // Whenever a link is clicked in the top navigation bar/menu
    Navbar_Click = "Navbar - Click",

    // When a creator clicks to view the payments of any live
    LiveCard_ViewPayments = "Live Card - View Payments",

    // When a guest or attendee clicks the "notify me" button on any live
    LiveCard_ClickNotify = "Live Card - Click Notify",

    // When a guest successfully submits their email to be notified about a creators' lives
    LiveCard_NotifyFinish = "Live Card - Notify Finish",

    // When a creator clicks to start their upcoming live when ready
    LiveCard_ClickStart = "Live Card - Click Start",

    // When a guest clicks "attend" on any live and goes toward checkout
    LiveCard_ClickAttend = "Live Card - Click Attend",

    // When any user clicks a social link under a creator on any live
    LiveCard_ClickSocial = "Live Card - Click Social",

    // When a live room actually starts
    Live_Start = "Live - Start",

    // Whenever an attendee or creator joins a live room
    Live_Join = "Live - Join",

    // Whenever an attendee or creator leaves a live room
    Live_Leave = "Live - Leave",

    // When a recording is started in a live room
    Live_RecordingStart = "Live - Recording Start",

    // When a recording is finished in a live room
    Live_RecordingFinish = "Live - Recording Finish",

    // When a recording error occurs in a live room
    Live_RecordingError = "Live - Recording Error",

    // When a guest enters land on the first page of live
    // the live purchase flow
    PurchaseLive_Start = "Purchase Live - Start",

    // When a guest enters their email to purchase a live
    // and is then redirected to a checkout page
    PurchaseLive_SubmitEmail = "Purchase Live - Submit Email",

    // Just before a guest is redirected to a checkout page for a live
    PurchaseLive_BeginCheckout = "Purchase Live - Begin Checkout",

    // When an attendee successfully purchases a live
    PurchaseLive_Finish = "Purchase Live - Finish",

    // When a guest enters land on the first page of
    // the course purchase flow
    PurchaseCourse_Start = "Purchase Course - Start",

    // When a guest enters their email to purchase a course
    // and is then redirected to a checkout page
    PurchaseCourse_SubmitEmail = "Purchase Course - Submit Email",

    // Just before a guest is redirected to a checkout page for a course
    PurchaseCourse_BeginCheckout = "Purchase Course - Begin Checkout",

    // When an attendee successfully purchases a course
    PurchaseCourse_Finish = "Purchase Course - Finish",

    // When an attendee clicks a social link on a creator during feedback
    LiveFeedback_ClickSocial = "Live Feedback - Click Social",

    // When a guest lands on the first page of the
    // live purchase flow
    PurchaseRecording_Start = "Purchase Recording - Start",

    // When a guest enters their email to purchase a recording
    // and is then redirected to a checkout page
    PurchaseRecording_SubmitEmail = "Purchase Recording - Submit Email",

    // Just before a guest is redirected to a checkout page for a recording
    PurchaseRecording_BeginCheckout = "Purchase Recording - Begin Checkout",

    // When an attendee successfully purchases a recording
    PurchaseRecording_Finish = "Purchase Recording - Finish",

    // When a guest clicks "purchase" on a live recording
    LiveCard_ClickPurchaseRecording = "Live Card - Click Purchase Recording",

    // When a guest submits their email on the homepage sign up box
    // and is redirected to a prefilled signup page
    Home_ClickSignUp = "Home - Click Sign Up",

    // Whenever the share modal appears
    Share_Start = "Share - Start",

    // Whenever the share modal is dismissed without sharing
    Share_Cancelled = "Share - Cancelled",

    // Whenever a share is attempted from the share modal
    // with the method of sharing selected therein
    ShareModal_ClickMethod = "Share Modal - Click Method",

    // Whenever a link is shared successfully using the share modal
    Share_Finish = "Share - Finish",

    // Whenever a user clicks on "book a call"
    BookCall_Click = "Book Call - Click",

    // When a creator starts the verification process
    Verification_Start = "Verification - Start",

    // When a creator successfully exists the verification process
    // N.B. this does not mean they are now verified, see "Verification Status - X"
    Verification_Finish = "Verification - Finish",

    // When Stripe reports a creators' verifications status as pending
    // indicating a creator has submitted their details for verification
    VerificationStatus_Pending = "Verification Status - Pending",

    // When a creator has succesfully verified their identity on Stripe
    VerificationStatus_Success = "Verification Status - Success",

    // When a waiting list member of a creator receives (or should receive)
    // an email indicating new activity for the creator
    WaitingList_Receive = "Waiting List - Receive",

    // When a new recording is discovered in the database for a creator
    UploadRecording_Finish = "Upload Recording - Finish",

    // When a guest opens a marketing campaign landing page
    CampaignLanding_Start = "Campaign Landing - Start",

    // When a guest submits their email on a marketing campaign landing page
    CampaignLanding_SubmitEmail = "Campaign Landing - Submit Email",

    // When a guest successfully completes a marketing campaign landing page
    CampaignLanding_Finish = "Campaign Landing - Finish",

    // When a user lands on the subscription input entry page
    PurchaseSubscription_Start = "Purchase Subscription - Start",

    // When a guest enters their email to purchase a subscription
    // and is then redirected to a checkout page
    PurchaseSubscription_SubmitEmail = "Purchase Subscription - Submit Email",

    // Just before a guest is redirected to a checkout page for a subscription
    PurchaseSubscription_BeginCheckout = "Purchase Subscription - Begin Checkout",

    // When a user successfully purchases a subscription
    PurchaseSubscription_Finish = "Purchase Subscription - Finish",

    // When a subscription is cancelled, triggered by the backend
    PlatformSubscription_Cancelled = "Platform Subscription - Cancelled",

    // When a user clicks to create a live from creator profile
    OnboardingChecklist_ClickCreateLive = "Onboarding Checklist - Click Create Live",

    // When a user clicks to setup subscription from creator profile
    OnboardingChecklist_ClickSetupProfile = "Onboarding Checklist - Click Setup Profile",

    // When a user clicks to setup subscription from creator profile
    OnboardingChecklist_ClickSubscription = "Onboarding Checklist - Click Subscription",

    // When a user clicks to promote a live from creator profile
    OnboardingChecklist_ClickPromoteLive = "Onboarding Checklist - Click Promote Live",

    // When a user clicks to promote a live from creator profile
    OnboardingChecklist_ClickUnlockDasboard = "Onboarding Checklist - Click Unlock Dashboard",

    // When a user clicks to promote a live from creator profile
    OnboardingChecklist_ClickSetupCommunity = "Onboarding Checklist - Click Setup Community",

    // When a creator lands on the subscription creation page
    CreateSubscription_Start = "Create Subscription - Start",

    // When a creator successfully creates their subscription
    CreateSubscription_Finish = "Create Subscription - Finish",

    // When a user lands on the refer a creator page
    ReferCreator_Start = "Refer Creator - Start",

    // When a user successfully refers a creator
    ReferCreator_Finish = "Refer Creator - Finish",

    // When a transactional email reaches a user's emails
    TransactionalEmail_Deliver = "Transactional Email - Deliver",

    // When a transactional email is opened
    TransactionalEmail_Open = "Transactional Email - Open",

    // When a link in a transactional email is clicked
    TransactionalEmail_Click = "Transactional Email - Click",

    // When an email fails to be delivered
    TransactionalEmail_Fail = "Transactional Email - Fail",

    // When a new channel is created
    Community_CreateChannel = "Community - Create Channel",

    // When a channel is updated
    Community_RenameChannel = "Community - Rename Channel",

    // When a channel is deleted
    Community_DeleteChannel = "Community - Delete Channel",

    // When a new post is created
    Community_CreatePost = "Community - Create Post",

    // When a post is edited
    Community_EditPost = "Community - Edit Post",

    // When a new post is deleted by creator
    Community_DeletePost = "Community - Delete Post",

    // When a new comment is created
    Community_CreateComment = "Community - Create Comment",

    // When a user replies to a comment
    Community_ReplyToComment = "Community - Reply To Comment",

    // When a post is pinned to channel
    Community_PinPost = "Community - Pin Post",

    // When a user clicks to become a member on new creator page
    Creator_BecomeMember = "Creator - Become member",

    // When a user clicks watch 100ms live demo
    Creator_WatchDemo = "Creator - Watch demo",

    // When a user clicks to upload a file
    AISnippet_UploadFileClick = "AI Snippet - Upload File Click",

    // When a user pastes youtube video url
    AISnippet_PasteYoutubeUrl = "AI Snippet - Paste Youtube Url",

    // When a user clicks "Create clips"
    AISnippet_CreateClips = "AI Snippet - Create Clips",

    // When a user chooses the AI clips format
    AISnippet_ClickFormat = "AI Snippet - Click Format",

    // When a user uploades a video
    AISnippet_UploadVideo = "AI Snippet - Upload Video",

    // When a user clicks "Next" to generate snippets (after choosing format option)
    AISnippet_GenerateSnippets = "AI Snippet - Generate Snippets",

    // When a user clicks to view a clip
    AISnippet_ViewClipClick = "AI Snippet - View Clip Click",

    // When a user clicks to download a clip
    AISnippet_DownloadClip = "AI Snippet - Download Clip",

    // When a user clicks to download video
    AISnippet_DownloadVideo = "AI Snippet - Download Video",

    // When a user clicks to download audio
    AISnippet_DownloadAudio = "AI Snippet - Download Audio",

    // When a user clicks to download transcript
    AISnippet_DownloadTranscript = "AI Snippet - Download Transcript",

    // When a user clicks on a hashtag
    AISnippet_CopyHashtag = "AI Snippet - Copy Hashtag",

    // When a user clicks edit templates from the list of brand styles on a project clip card
    AISnippet_EditTemplates = "AI Snippet - Edit Templates",

    // When a user sees the platform subscription plans paywall
    AISnippet_CreateNewClipsPaywall = "AI Snippet - Create New Clips Paywall",

    // When a user closes the paywall modal
    AISnippet_ClosePaywallModal = "AI Snippet - Close Paywall Modal",

    // When a user starts subscription purchase (clicks "Get Started")
    AISnippet_SubscriptionStart = "AI Snippet - Subscription Start",

    // When a user finishes subscription purchase
    AISnippet_SubscriptionFinish = "AI Snippet - Subscription Finish",

    // When a user subscription converts from the trial
    AISnippet_SubscriptionConversion = "AI Snippet - Subscription Conversion",

    // When a user subscription renews
    AISnippet_SubscriptionRenewal = "AI Snippet - Subscription Renewal",

    // When a user views the Idp results
    AISnippet_ResultsView = "AI Snippet - Result View",

    // When a user clicks retry for a failed video
    AISnippet_VideoRetryClick = "AI Snippet - Video Retry Click",

    // When a user clicks on which resolution to download the clip in
    AISnippet_DownloadResolutionClick = "AI Snippet - Download Resolution Click",

    // When a user clicks on which resolution to download the clip in
    AISnippet_InsufficientResultsModal = "AI Snippet - Insufficient Results Modal",

    // When a user clicks fine tune (edit) clip button
    EditClip_Click = "Edit Clip Click",

    // When a user saves and compiles clip
    Editor_SaveCompileClick = "Editor - Save Compile Click",

    // When a user clicks to the timeline
    Editor_ClickTimeline = "Editor - Click Timeline",

    // When a user splits click
    Editor_ClickSplit = "Editor - Click Split",

    // When a user undos a change
    Editor_ClickUndo = "Editor - Click Undo",

    // When a user sets clip as start,
    Editor_StartClip = "Editor - Clip Start",

    // When a user sets clip as end
    Editor_EndClip = "Editor - Clip End",

    // when a user edits captions
    Editor_EditCaption = "Editor - Edit Caption",

    //When a user removes a word
    Editor_RemoveWord = "Editor - Remove Word",

    // When a user sets a caption as start
    Editor_SetStartCaption = "Editor - Caption Start",

    // When a user sets a caption as end
    Editor_SetEndCaption = "Editor - Caption End",

    // When a user toggles show captions
    Editor_ToggleCaptions = "Editor - Toggle Captions",

    // When a user toggles punctuation
    Editor_TogglePunctuation = "Editor - Toggle Punctuation",

    // When a user changes the caption style
    Editor_ChangeCaptionsStyle = "Editor - Change Captions Style",

    // When a user changes the caption position
    Editor_ChangeCaptionsPosition = "Editor - Change Captions Position",

    // When a user customizes style
    Editor_CustomiseStyle = "Editor - Customize Style",

    // When a user changes the font
    Editor_ChangeFont = "Editor - Change Font",

    // When a user changes the text colour
    Editor_ChangeTextColor = "Editor - Change Text Colour",

    // When a user changes the text stroke
    Editor_ChangeTextStroke = "Editor - Change Text Stroke",

    // When a user uppercases all text
    Editor_UppercaseText = "Editor - Uppercase Text",

    // When a user changes the text background
    Editor_ChangeTextBackground = "Editor - Change Text Background",

    // When a user claims extra minutes
    AdditionalMinutes_Claim = "Additional Minutes - Claim",

    // When a user starts the cancel subscription flow
    CancelSubscription_Start = "Cancel Subscription - Start",

    // When a user switches to a monthly plan during the cancel subscription flow
    CancelSubscription_SwitchMonthly = "Cancel Subscription - Switch Monthly",

    // When a discount is applied to a user and they are navigated to the success page
    CancelSubscription_DiscountApplied = "Cancel Subscription - Discount Applied",

    // When a user cancels their subscription by clicking downgrade
    CancelSubscription_Cancel = "Cancel Subscription - Cancel",

    // When a user opts to keeps their clips in the cancel subscription flow
    CancelSubscription_KeepClips = "Cancel Subscription - Keep Clips",

    // When a user opts to visit the learning center in the cancel subscription flow
    CancelSubscription_VisitLearningCenter = "Cancel Subscription - Visit Learning Center",

    // When a user opts to visit the blog in the cancel subscription flow
    CancelSubscription_VisitBlog = "Cancel Subscription - Visit Blog",

    // When a user opts to ignore education incentives in the cancel subscription flow
    CancelSubscription_IgnoreEducationIncentives = "Cancel Subscription - Ignore Education Incentives",

    // When a user opts to ignore list of what they will lose in the cancel subscription flow
    CancelSubscription_IgnoreClipDisincentives = "Cancel Subscription - Ignore Clip Disincentives",

    // When a social platform is connected
    SchedulePost_Connect = "Schedule Post - Connect",

    // When a social provider user is created
    SchedulePost_CreateSocialAccountUser = "Schedule Post - Create Social Provider User",

    // When a social platform is revoked
    SchedulePost_Revoke = "Schedule Post - Revoke",

    // When a scheduled post is on progress
    SchedulePost_Execute = "Schedule Post - Execute",

    // When a post is scheduled
    SchedulePost_Schedule = "Schedule Post - Schedule",

    // When a scheduled post is cancelled
    SchedulePost_Cancel = "Schedule Post - Cancel",

    // When a scheduled post encounters an issue
    SchedulePost_Error = "Schedule Post - Error",

    // When a scheduled post is updated
    SchedulePost_Update = "Schedule Post - Update",

    // When a scheduled post is published
    SchedulePost_Publish = "Schedule Post - Publish",

    // When a social platform requires re-authentication
    SchedulePost_Reauth = "Schedule Post - Reauth",

    // When a user clicks show chapters in project page
    Project_ShowChapters = "Project - Show Chapters",

    // When a user clicks copy chapters in project page
    Project_CopyChapters = "Project - Copy Chapters",
}

export enum LinkedInEvents {
    LINKEDIN_LEAD_GEN_EVENT_ID = "10945762",
    LINKEDIN_BUY_PLATFORM_SUBSCRIPTION_START_EVENT_ID = "11526202",
    LINKEDIN_BUY_PLATFORM_SUBSCRIPTION_FINISH_EVENT_ID = "11384898",
    LINKEDIN_AI_SIGNUP_FINISH = "13454770",
    LINKEDIN_AI_CREATE_CLIPS = "13454778",
    LINKEDIN_AI_CREATE_CLIPS_PAYWALL = "13454786",
    LINKEDIN_AI_VIEW_RESULTS = "13454794",
    LINKEDIN_AI_SUBSCRIPTION_START = "13454762",
    LINKEDIN_AI_SUBSCRIPTION_FINISH = "13454754",
}

export type TEventsWithEnhancedConversion = AE.AISnippet_SubscriptionFinish | AE.Signup_Finish

export const AnalyticsEnhancedConversionEvents: Record<TEventsWithEnhancedConversion, string> = {
    [AE.AISnippet_SubscriptionFinish]: "11017646537/mgDNCI_WpdsYEMnjz4Up",
    [AE.Signup_Finish]: "11017646537/s5DHCK__goYYEMnjz4Up",
}

export interface AnalyticsPayload {
    [AE.Page_Visit]: {
        path: string
        title: string
        previousPagePath: string
        previousPageTitle?: string
        source?: string
    }
    [AE.Application_Error]: {
        message: string
    }
    [AE.Login_Start]: void
    [AE.Login_ClickSubmit]: void
    [AE.Login_Finish]: void
    [AE.Login_Error]: { message?: string }
    [AE.Signup_Start]: {
        flow?: "snippets"
    }
    [AE.Signup_ClickSubmit]: void
    [AE.Signup_SubmitClaimUsername]: { username?: string }
    [AE.Signup_ResendEmailVerification]: { email: string }
    [AE.ToggleProfile_UpdateToCreator]: void
    [AE.Signup_Finish]: {
        email: string
        name?: string
        username?: string
        isCreator: boolean
        value?: number
        flow?: "snippets"
        user_data?: { email: string }
        send_to?: string
    }
    [AE.Signup_Error]: { message?: string }
    [AE.CreateLive_Start]: void
    [AE.CreateLive_ClickSubmit]: void
    [AE.CreateLive_Finish]: {
        startDate: string
        title: string
    }
    [AE.CreateCourse_Start]: void
    [AE.CreateCourse_ClickSubmit]: void
    [AE.CreateCourse_Finish]: void
    [AE.EditCourse_Start]: void
    [AE.Editor_ClickTimeline]: void
    [AE.Editor_ClickSplit]: void
    [AE.Editor_ClickUndo]: void
    [AE.Editor_StartClip]: void
    [AE.Editor_EndClip]: void
    [AE.Editor_EditCaption]: void
    [AE.Editor_SetStartCaption]: void
    [AE.Editor_SetEndCaption]: void
    [AE.Editor_RemoveWord]: void
    [AE.Editor_ToggleCaptions]: void
    [AE.Editor_TogglePunctuation]: void
    [AE.Editor_ChangeCaptionsStyle]: void
    [AE.Editor_ChangeCaptionsPosition]: void
    [AE.Editor_CustomiseStyle]: void
    [AE.Editor_ChangeFont]: void
    [AE.Editor_ChangeTextColor]: void
    [AE.Editor_ChangeTextStroke]: void
    [AE.Editor_UppercaseText]: void
    [AE.Editor_ChangeTextBackground]: void
    [AE.EditCourse_ClickSubmit]: void
    [AE.EditCourse_Finish]: void
    [AE.EditLive_Start]: {
        startDate: string
        title: string
    }
    [AE.EditLive_ClickSubmit]: void
    [AE.EditLive_Finish]: void
    [AE.DeleteLive_Start]: void
    [AE.DeleteLive_Finish]: void
    [AE.Navbar_Click]: {
        title: string
        url: string
        newTab?: boolean
    }
    [AE.LiveCard_ViewPayments]: {
        creatorSlug: string
        liveSlug: string
    }
    [AE.LiveCard_ClickStart]: {
        creatorSlug: string
        liveSlug: string
    }
    [AE.LiveCard_ClickAttend]: {
        creatorSlug: string
        liveSlug: string
    }
    [AE.LiveCard_ClickNotify]: {
        creatorSlug: string
        liveSlug: string
    }
    [AE.LiveCard_NotifyFinish]: {
        creatorSlug: string
        liveSlug: string
        email: string
    }
    [AE.LiveCard_ClickSocial]: {
        creatorSlug: string
        liveSlug: string
        socialUrl: string
        platform: "Tiktok" | "LinkedIn" | "Instagram" | "Twitter" | "Youtube"
    }
    [AE.Live_Start]: {
        creatorSlug: string
        liveSlug: string
        isOwner: boolean
    }
    [AE.Live_Join]: {
        creatorSlug: string
        liveSlug: string
        isOwner: boolean
        withAudio: boolean
        withVideo: boolean
        sessionId: string
    }
    [AE.Live_Leave]: {
        creatorSlug: string
        liveSlug: string
        isOwner: boolean
        sessionId: string
    }
    [AE.Live_RecordingStart]: {
        creatorSlug: string
        liveSlug: string
        type?: string
        isOwner?: boolean
        sessionId?: string
        userName?: string
        userId?: string
    }
    [AE.Live_RecordingFinish]: {
        creatorSlug: string
        liveSlug: string
        isOwner?: boolean
        sessionId?: string
        userName?: string
        userId?: string
        liveImageUrl?: string
    }
    [AE.Live_RecordingError]: {
        creatorSlug: string
        liveSlug: string
        isOwner?: boolean
        sessionId?: string
        userName?: string
        userId?: string
    }
    [AE.PurchaseLive_Start]: {
        liveTitle: string
        liveSlug: string
        liveDate: string
        livePrice: string
        creatorSlug: string
        liveImageUrl: string
        liveDescription?: string | null
    }
    [AE.PurchaseLive_BeginCheckout]: {
        liveTitle: string
        liveSlug: string
        liveDate: string
        livePrice: string
        creatorSlug: string
        liveImageUrl: string
        liveDescription?: string | null
    }
    [AE.PurchaseLive_SubmitEmail]: {
        creatorSlug: string
        liveSlug: string
        email: string
    }
    [AE.PurchaseLive_Finish]: {
        creatorSlug: string
        liveSlug: string
        startDate: string
        value: number
        totalAttendees?: string | number
        cost?: string | number
        currency?: string
        durationMinutes?: string | number
    }
    [AE.PurchaseCourse_Start]: {
        courseTitle: string
        courseSlug: string
        courseDate: string
        courseImageUrl: string
        courseDescription?: string | null
        creatorSlug: string
    }
    [AE.PurchaseCourse_BeginCheckout]: {
        courseTitle: string
        courseSlug: string
        courseDate: string
        courseImageUrl: string
        courseDescription?: string | null
        creatorSlug: string
    }
    [AE.PurchaseCourse_SubmitEmail]: {
        creatorSlug: string
        courseSlug: string
        email: string
    }
    [AE.PurchaseCourse_Finish]: {
        creatorSlug: string
        courseSlug: string
        startDate: string
    }
    [AE.PurchaseRecording_BeginCheckout]: {
        liveTitle: string
        liveSlug: string
        liveDate: string
        livePrice: string
        creatorSlug: string
        recordingId: string
        liveDescription?: string | null
    }
    [AE.LiveFeedback_ClickSocial]: {
        creatorSlug: string
        liveSlug: string
        socialUrl: string
        platform: "Tiktok" | "LinkedIn" | "Instagram" | "Twitter" | "Youtube"
    }
    [AE.PurchaseRecording_Start]: {
        creatorSlug: string
        liveSlug: string
    }
    [AE.PurchaseRecording_SubmitEmail]: {
        creatorSlug: string
        liveSlug: string
        email: string
    }
    [AE.PurchaseRecording_Finish]: {
        creatorSlug: string
        liveSlug: string
        cost?: string | number
        value: number
        currency?: string
        durationMinutes?: string | number
    }
    [AE.LiveCard_ClickPurchaseRecording]: void
    [AE.Home_ClickSignUp]: {
        email: string
    }
    [AE.Share_Start]: {
        location:
            | "Live Card"
            | "Creator Nav"
            | "Live Created"
            | "Feedback Form"
            | "Purchase Complete"
        creatorSlug?: string
        liveSlug?: string
    }
    [AE.Share_Cancelled]: void
    [AE.Share_Finish]: void
    [AE.ShareModal_ClickMethod]: {
        method: "facebook" | "linkedin" | "twitter" | "copyUrl"
    }
    [AE.Verification_Start]: void
    [AE.Verification_Finish]: void
    [AE.VerificationStatus_Pending]: void
    [AE.VerificationStatus_Success]: void
    [AE.WaitingList_Receive]: {
        creatorName: string
        liveTitle: string
        liveUrl: string
    }
    [AE.UploadRecording_Finish]: {
        liveTitle: string
        liveSlug: string
        liveDate: string
        livePrice: string
        creatorSlug: string
        creatorName: string
        recordingId: string
        liveDescription?: string | null
    }
    [AE.CampaignLanding_Start]: {
        title: string
        description: string
        slug: string
        type: string
        ctaText: string
        ctaUrl: string
        firstName?: string
        email?: string
    }
    [AE.CampaignLanding_SubmitEmail]: {
        title: string
        description: string
        slug: string
        type: string
        ctaText: string
        ctaUrl: string
        firstName: string
        email: string
    }
    [AE.CampaignLanding_Finish]: {
        title: string
        description: string
        slug: string
        type: string
        ctaText: string
        ctaUrl: string
        firstName: string
        email: string
    }
    [AE.PurchaseSubscription_Start]: {
        creatorSlug: string
    }
    [AE.PurchaseSubscription_SubmitEmail]: {
        creatorSlug: string
        email: string
    }
    [AE.PurchaseSubscription_BeginCheckout]: {
        subscriptionImageUrl?: string
        creatorSlug: string
        email: string
    }
    [AE.PurchaseSubscription_Finish]: {
        creatorSlug: string
        value: number
        cost?: string | number
        currency?: string
    }
    [AE.PlatformSubscription_Cancelled]: void
    [AE.OnboardingChecklist_ClickCreateLive]: void
    [AE.OnboardingChecklist_ClickSubscription]: void
    [AE.OnboardingChecklist_ClickSetupProfile]: void
    [AE.OnboardingChecklist_ClickPromoteLive]: void
    [AE.OnboardingChecklist_ClickUnlockDasboard]: void
    [AE.OnboardingChecklist_ClickSetupCommunity]: void
    [AE.CreateSubscription_Start]: void
    [AE.CreateSubscription_Finish]: void
    [AE.ReferCreator_Start]: void
    [AE.ReferCreator_Finish]: {
        creatorName: string
        creatorEmail: string
        creatorSocialLink: string
        referrerEmail: string
    }
    [AE.TransactionalEmail_Deliver]: {
        templateId: string
        emailSubject: string
    }
    [AE.TransactionalEmail_Open]: {
        templateId: string
        emailSubject: string
    }
    [AE.TransactionalEmail_Click]: {
        templateId: string
        emailSubject: string
        url: string
    }
    [AE.TransactionalEmail_Fail]: {
        templateId: string
        emailSubject: string
        failureType: string
        failureReason: string
    }
    [AE.Community_CreateChannel]: {
        creatorSlug: string
        channelSlug: string
        groupId: string
    }
    [AE.Community_RenameChannel]: {
        creatorSlug: string
        oldSlug: string
        updatedSlug: string
    }
    [AE.Community_DeleteChannel]: {
        creatorSlug: string
        channelSlug: string
        groupId: string
    }
    [AE.Community_CreatePost]: {
        creatorSlug: string
        channelSlug: string
        postId: string
    }
    [AE.Community_EditPost]: {
        creatorSlug: string
        channelSlug: string
        postId: string
    }
    [AE.Community_DeletePost]: {
        creatorSlug: string
        channelSlug: string
        postId: string
    }
    [AE.Community_CreateComment]: {
        creatorSlug: string
        channelSlug: string
        postId: string
        commentId: string
    }
    [AE.Community_ReplyToComment]: {
        creatorSlug: string
        channelSlug: string
        postId: string
        commentId: string
        parentId: string
    }
    [AE.Community_PinPost]: {
        creatorSlug: string
        channelSlug: string
        postId: string
    }
    [AE.Creator_BecomeMember]: {
        creatorSlug: string
    }
    [AE.BookCall_Click]: void
    [AE.Creator_WatchDemo]: void
    [AE.AISnippet_UploadFileClick]: void
    [AE.AISnippet_UploadVideo]: {
        format: string
        title: string
        language: string | null
    }
    [AE.AISnippet_PasteYoutubeUrl]: {
        url: string
    }
    [AE.AISnippet_CreateClips]: {
        location: "landing-page" | "projects" | "project-detail"
        videoUrl?: string
        isDummyMode?: boolean
    }
    [AE.AISnippet_GenerateSnippets]: {
        videoUrl: string
        isDummyMode: boolean
    }
    [AE.AISnippet_ClickFormat]: {
        format: string
        isDummyMode: boolean
    }
    [AE.AISnippet_DownloadClip]: {
        format: string
        title: string
        duration: number
        isDummyMode: boolean
    }
    [AE.AISnippet_CopyHashtag]: {
        hashtag: string
    }
    [AE.AISnippet_EditTemplates]: void
    [AE.AISnippet_ViewClipClick]: {
        title: string
        isDummyMode: boolean
    }
    [AE.AISnippet_DownloadVideo]: {
        title: string
        format: string
        duration: number
    }
    [AE.AISnippet_DownloadAudio]: {
        title: string
        format: string
        duration: number
    }
    [AE.AISnippet_DownloadTranscript]: {
        title: string
        snippedId: string
    }
    [AE.AISnippet_VideoRetryClick]: {
        title: string
        location: "projects" | "clips"
    }
    [AE.AISnippet_InsufficientResultsModal]: {
        title: string
        totalClips: number
    }
    [AE.AISnippet_CreateNewClipsPaywall]: {
        location: "landing-page" | "projects" | "project-detail" | "retry-button" | "pop-up"
    }
    [AE.AISnippet_ClosePaywallModal]: void
    [AE.AISnippet_SubscriptionStart]: {
        email: string
        plan: string
    }
    [AE.AISnippet_SubscriptionFinish]: {
        subscriptionId: string
        email: string
        plan: string
        value: number
        currency: string
        status: EPlatformSubscriptionStatus
        action?: ESubscriptionOption
        promocode?: string
        user_data?: { email: string }
        send_to: string
    }
    [AE.AISnippet_SubscriptionConversion]: {
        subscriptionId: string
        transaction_id: string
        email: string
        plan: string
        value: number
        currency: string
        isRenewal?: boolean
    }
    [AE.AISnippet_SubscriptionRenewal]: {
        subscriptionId: string
        transaction_id: string
        email: string
        plan: string
        value: number
        currency: string
        isRenewal?: boolean
    }
    [AE.AISnippet_ResultsView]: {
        type: "email" | "page"
        result: "success" | "error"
        isDummyMode?: boolean
        videoTitle?: string
        createdAt?: string
    }
    [AE.AISnippet_DownloadResolutionClick]: {
        resolution: EVideoResolution
    }
    [AE.EditClip_Click]: {
        title: string
    }
    [AE.Editor_SaveCompileClick]: {
        clipId: string
    }
    [AE.AdditionalMinutes_Claim]: {
        totalMinutes: number
        expiresAt?: string
    }
    [AE.CancelSubscription_Start]: void
    [AE.CancelSubscription_KeepClips]: void
    [AE.CancelSubscription_VisitLearningCenter]: {
        pageUrl: string
        pageName: string
    }
    [AE.CancelSubscription_VisitBlog]: {
        pageUrl: string
        pageName: string
    }
    [AE.CancelSubscription_SwitchMonthly]: void
    [AE.CancelSubscription_IgnoreClipDisincentives]: void
    [AE.CancelSubscription_IgnoreEducationIncentives]: void
    [AE.CancelSubscription_Cancel]: void
    [AE.CancelSubscription_DiscountApplied]: {
        plan: string
        currency: string
        value: number
        discount: number
    }
    [AE.SchedulePost_Connect]: {
        platform: EActiveSocialPlatform
        userId: string
        socialAccountUserId: string
    }
    [AE.SchedulePost_Revoke]: {
        platform: EActiveSocialPlatform
        userId: string
    }
    [AE.SchedulePost_Reauth]: {
        platform: EActiveSocialPlatform
        userId: string
        taskId: string
    }
    [AE.SchedulePost_CreateSocialAccountUser]: {
        platform: EActiveSocialPlatform
        userId: string
        socialAccountUserId: string
    }
    [AE.SchedulePost_Schedule]: {
        platform: EActiveSocialPlatform
        userId: string
        taskId: string
    }
    [AE.SchedulePost_Execute]: {
        platform: EActiveSocialPlatform
        userId: string
        taskId: string
    }
    [AE.SchedulePost_Cancel]: {
        platform: EActiveSocialPlatform
        userId: string
        taskId: string
    }
    [AE.SchedulePost_Error]: {
        platform: EActiveSocialPlatform
        userId: string
        taskId: string
        error?: string
    }
    [AE.SchedulePost_Update]: {
        platform: EActiveSocialPlatform
        userId: string
        taskId: string
    }
    [AE.SchedulePost_Publish]: {
        platform: EActiveSocialPlatform
        userId: string
        taskId: string
        postIds: string[]
    }
    // @deprecated - we no longer support chapters
    [AE.Project_ShowChapters]: void
    // @deprecated - we no longer support chapters
    [AE.Project_CopyChapters]: void
}

export interface AnalyticsPayloadCommon {
    staging?: boolean
    version?: string
}

export enum EAnalyticsProvider {
    GoogleAnalytics = "GoogleAnalytics",
    GoogleTagManager = "GoogleTagManager",
    FacebookPixel = "FacebookPixel",
    Mixpanel = "Mixpanel",
    Drip = "Drip",
}
