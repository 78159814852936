import { LivePrice } from "@prisma/client"

import { BaseLive } from "../server/dao/live_dao"
import { printMoney } from "./currency_util"
import { sumNumbers } from "./number_util"

export const isString = (value: any): value is string => typeof value === "string"

export const removeAlphaNumericCharacters = (str: string) => str.replace(/[^a-zA-Z ]/g, "")

export const formatUsername = (username: string) =>
    username
        .replace(/[^a-zA-Z0-9]/g, "")
        .replace(/\s/g, "")
        .toLowerCase()

export const capitalizeFirstLetter = (str?: string) =>
    str ? str.charAt(0).toUpperCase() + str.slice(1) : str

export const formatFullName = (user: { firstName?: string | null; lastName?: string | null }) =>
    user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ""

export const formatLivePriceForAnalytics = (live: BaseLive) => {
    const { price } = live
    return `${formatPriceForDisplayValue(price!)}`
}

export const formatPriceForDisplayValue = (price: LivePrice) => {
    const isHigherDenomCurrency = ["USD", "GBP"].includes(price.currency)
    const isDivisible = (price?.totalInLowestDenom ?? 0) > 0
    const shouldRaiseDenom = isHigherDenomCurrency && isDivisible

    return shouldRaiseDenom ? price.totalInLowestDenom / 100 : 0
}

export const formatValueForCurrency = (value: string, currency: string) => {
    const number = parseInt(value)
    return printMoney({ total: number, options: { currency } })
}

export const getCharCountFromObject = (value: any): number => {
    if (typeof value === "string") {
        return value.length
    }
    if (typeof value === "object" && value.constructor.name === "Object") {
        return sumNumbers(Object.values(value).map((v) => getCharCountFromObject(v)))
    }
    if (Array.isArray(value)) {
        return sumNumbers(value.map((v) => getCharCountFromObject(v)))
    }
    return 0
}

// converts time in seconds to human readable hours/minutes/seconds string
export const secondsToHms = (
    d: number,
    abbreviate: { minutes: boolean; hours: boolean },
): string => {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)

    const hourStr = abbreviate?.hours ? "hr" : "hour"
    const minuteStr = abbreviate?.minutes ? "m" : "min"

    const hDisplay = h > 0 ? h + (h == 1 ? ` ${hourStr} ` : ` ${hourStr}s `) : ""
    const mDisplay =
        m > 0
            ? m + (m == 1 ? ` ${minuteStr}` : ` ${minuteStr}${abbreviate?.minutes ? "" : "s"}`)
            : ""

    return hDisplay + mDisplay
}

export const formatDurationToTimestamp = (durationSeconds: number) => {
    const hours = Math.floor(durationSeconds / 3600)
    const minutes = Math.floor((durationSeconds % 3600) / 60)
    const seconds = Math.floor(durationSeconds % 60)

    const pad = (num: number) => (num < 10 ? "0" + num : num.toString())

    return hours > 0
        ? `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
        : `${pad(minutes)}:${pad(seconds)}`
}

export const formatTimeForClipSeek = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
}

export const cleanIdentifier = (str: string) => str?.toLowerCase().trim()

export const trimStringToXChars = (str: string, maxChars: number) => {
    return str.length < maxChars ? str : `${str.substring(0, maxChars)}...`
}

export const containsSpecialChars = (str: string) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    return specialChars.test(str)
}

export const containsSubstrings = (mainString: string, substrings: string[], strict = false) => {
    if (strict) {
        return substrings.every((substring) => mainString.includes(substring))
    }
    return substrings.some((substring) => mainString.includes(substring))
}

export const isValidUrl = (url: string) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i",
    ) // fragment locator

    return !!pattern.test(url)
}

export const cleanTextForFilename = (input: string) => {
    const reservedFileSystemNames = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i
    const leadingTrailingDots = /^\.+|\.+$/g
    const spaces = /\s/g
    const nonAlphaNumeric = /[^a-zA-Z0-9\- ]/g
    return input
        .toLowerCase()
        .replace(nonAlphaNumeric, "")
        .replace(reservedFileSystemNames, "")
        .replace(leadingTrailingDots, "")
        .replace(spaces, "-")
        .trim()
        .slice(0, 100)
}

const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"]

export const abbreviateNum = (number: number) => {
    if (number === 0) {
        return 0
    }

    // Thanks to Waylon Flinn

    // what tier? (determines SI symbol)
    // @ts-ignore
    const tier = (Math.log10(number) / 3) | 0 // eslint-disable-line

    // if zero, we don't need a suffix
    if (tier === 0) {
        return number
    }

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier]
    const scale = Math.pow(10, tier * 3)

    // scale the number
    let scaled: string | number = number / scale

    // format number
    scaled = scaled.toFixed(1)

    // with suffix
    return `${scaled}`.replace(".0", "") + suffix
}

export const getAlphabetic = (str: string): string => {
    return str.replace(/[^a-zA-Z]/g, "")
}

export const startsWithCapital = (word: string) => {
    return /^[A-Z]/.test(word)
}

export const formatDurationToMinutesSeconds = (duration: number) => {
    const minutes = Math.floor(duration / 60)
    const remainingSeconds = Math.floor(duration) % 60
    if (minutes === 0) {
        return `${remainingSeconds}s`
    }
    if (remainingSeconds === 0 && minutes > 0) {
        return `${minutes}m`
    }
    return `${minutes}m ${remainingSeconds}s`
}

// Define the languages with their ISO 639-1 codes, BCP 47 codes, and Unicode ranges
const LANGUAGE_RANGES = [
    {
        name: "Arabic",
        iso6391: "ar",
        bcp47: "ar",
        unicodeRanges: [
            [0x0600, 0x06ff],
            [0x0750, 0x077f],
            [0x08a0, 0x08ff],
            [0xfb50, 0xfdff],
            [0xfe70, 0xfeff],
            [0x10e60, 0x10e7f],
            [0x1ee00, 0x1eeff],
        ],
    },
    {
        name: "Urdu",
        iso6391: "ur",
        bcp47: "ur",
        unicodeRanges: [
            [0x064a, 0x064a],
            [0x0679, 0x067b],
            [0x067e, 0x067e],
            [0x0680, 0x0680],
            [0x0688, 0x0688],
            [0x0691, 0x0691],
            [0x06af, 0x06af],
            [0x06ba, 0x06ba],
            [0x06cc, 0x06cc],
            [0x06d2, 0x06d3],
        ],
    },
    {
        name: "Khmer",
        iso6391: "km",
        bcp47: "km",
        unicodeRanges: [
            [0x1780, 0x17ff], // Khmer Unicode block
            [0x19e0, 0x19ff], // Khmer Symbols
        ],
    },
    {
        name: "Hindi",
        iso6391: "hi",
        bcp47: "hi",
        unicodeRanges: [
            [0x0900, 0x097f],
            [0xa8e0, 0xa8ff],
        ],
    },
    {
        name: "Tamil",
        iso6391: "ta",
        bcp47: "ta",
        unicodeRanges: [[0x0b80, 0x0bff]],
    },
    {
        name: "Malayalam",
        iso6391: "ml",
        bcp47: "ml",
        unicodeRanges: [[0x0d00, 0x0d7f]],
    },
    {
        name: "Thai",
        iso6391: "th",
        bcp47: "th",
        unicodeRanges: [[0x0e00, 0x0e7f]],
    },
    {
        name: "Gujarati",
        iso6391: "gu",
        bcp47: "gu",
        unicodeRanges: [[0x0a80, 0x0aff]],
    },
    {
        name: "Nepali",
        iso6391: "ne",
        bcp47: "ne",
        unicodeRanges: [[0x0900, 0x097f], [(0xa8e0, 0xa8ff)]],
    },
    {
        name: "Vietnamese",
        iso6391: "vi",
        bcp47: "vi",
        unicodeRanges: [[0x1ea0, 0x1ef9]],
    },
    {
        name: "Bengali",
        iso6391: "bn",
        bcp47: "bn",
        unicodeRanges: [[0x0980, 0x09ff]],
    },
    {
        name: "Telugu",
        iso6391: "te",
        bcp47: "te",
        unicodeRanges: [[0x0c00, 0x0c7f]],
    },
    {
        name: "Amharic",
        iso6391: "am",
        bcp47: "am",
        unicodeRanges: [[0x1200, 0x137f]],
    },
    {
        name: "Sinhala",
        iso6391: "si",
        bcp47: "si",
        unicodeRanges: [[0x0d80, 0x0dff]],
    },
]
interface IScriptDetection {
    language: string
    iso6391: string
    bcp47: string
    text: string
    proportion: number
}

export const detectUnsupportedScripts = (text: string) => {
    const languageData: {
        [isoCode: string]: {
            name: string
            iso6391: string
            bcp47: string
            count: number
            text: string
        }
    } = {}

    let totalCharacters = 0

    // Iterate over each character in the input text
    for (const char of text) {
        const codePoint = char.codePointAt(0)
        if (codePoint === undefined) continue

        let matched = false

        // Check if the character falls within any language's Unicode ranges
        for (const lang of LANGUAGE_RANGES) {
            for (const [start, end] of lang.unicodeRanges) {
                if (codePoint >= start && codePoint <= end) {
                    // Initialize language data if it doesn't exist
                    if (!languageData[lang.iso6391]) {
                        languageData[lang.iso6391] = {
                            name: lang.name,
                            iso6391: lang.iso6391,
                            bcp47: lang.bcp47,
                            count: 0,
                            text: "",
                        }
                    }
                    // Update the language data
                    languageData[lang.iso6391].count += 1
                    languageData[lang.iso6391].text += char
                    totalCharacters += 1
                    matched = true
                    break // Break out of the inner loop if a match is found
                }
            }
            if (matched) break // Break out of the outer loop if a match is found
        }
    }

    // Prepare the result array with language proportions
    const results: IScriptDetection[] = []

    for (const isoCode in languageData) {
        const langInfo = languageData[isoCode]
        results.push({
            language: langInfo.name,
            iso6391: langInfo.iso6391,
            bcp47: langInfo.bcp47,
            text: langInfo.text,
            proportion: langInfo.count / totalCharacters,
        })
    }

    return results
}
