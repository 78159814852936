import { DefaultSeo, LocalBusinessJsonLd, LogoJsonLd, NextSeo, NextSeoProps } from "next-seo"
import Head from "next/head"
import { useRouter } from "next/router"
import React, { FC, useMemo, useState } from "react"

import _c from "../configs/constants"
import defaultMetaTags, { getPageTitle, titleTemplate } from "../configs/meta"
import { getFaviconNameForEnv } from "../utils/image_util"

export const BaseMetaTags = () => {
    const router = useRouter()
    const [noIndex] = useState(false)

    const pageUrl = useMemo(() => `${_c.BASE_URL}${router.asPath}`, [router.asPath])
    const logoUrl = useMemo(() => _c.OG_IMAGE_URL, [_c.OG_IMAGE_URL])
    const canonicalUrl = useMemo(() => `${_c.BASE_URL_CANONICAL}${router.asPath}`, [router.asPath])

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta name="theme-color" content="#FDEFE6" />
                <link rel="manifest" href="/site.webmanifest" />
                <meta key="og:url" property="og:url" content={pageUrl} />
                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <meta
                    name="viewport"
                    /* Please do not change these viewport settings. */
                    /* If you have an issue regarding scale, it is in your CSS. */
                    content="width=device-width, initial-scale=1, maximum-scale=5"
                />
                <link rel="shortcut icon" type="image/x-icon" href={`${getFaviconNameForEnv()}`} />
                <link rel="preconnect" href="https://apis.google.com" />
                <link rel="preconnect" href="https://firebaseapp.com" />
            </Head>
            <DefaultSeo {...defaultMetaTags} />
            <MetaTags canonical={canonicalUrl} openGraph={{ url: pageUrl }} />
            <LocalBusinessJsonLd
                id="https://livelink.ai"
                url="https://livelink.ai"
                description={defaultMetaTags.description as string}
                name="LiveLink"
                type="LocalBusiness"
                address={{
                    streetAddress: "Cline Road",
                    addressLocality: "London",
                    addressRegion: "London",
                    addressCountry: "UK",
                    postalCode: "N11 2NG",
                }}
                telephone="+44"
                images={[logoUrl]}
            />
            <LogoJsonLd logo={logoUrl} url="https://livelink.ai" />
            {noIndex && <NextSeo noindex />}
        </>
    )
}

export interface IMetaTagsProps extends NextSeoProps {}

const MetaTags: FC<IMetaTagsProps> = (props) => {
    const finalProps = { ...props }
    const { title } = finalProps
    // Set OpenGraph title automatically
    if (title && !props.openGraph?.title) {
        finalProps.openGraph = {
            ...(finalProps.openGraph || {}),
            title: getPageTitle(title),
        }
    }
    // Truncate description to an ideal length
    let { description } = finalProps
    if (description) {
        description =
            description.length < _c.MAX_LENGTH_META_DESC
                ? description
                : `${description.substring(0, _c.MAX_LENGTH_META_DESC)}...`
        finalProps.description = description
    }
    // Set OpenGraph description automatically
    if (description && !props.openGraph?.description) {
        finalProps.openGraph = {
            ...(finalProps.openGraph || {}),
            description,
        }
    }
    // Explicitly state twitter:image for platforms other than twitter
    // as some still rely on that prop even though twitter does not
    let twitterImage
    if (finalProps.openGraph?.images?.length) {
        twitterImage = finalProps.openGraph.images[0].url
    }
    if (title) {
        finalProps.titleTemplate = titleTemplate
    }
    return (
        <>
            <Head>{twitterImage && <meta name="twitter:image" content={twitterImage} />}</Head>
            <NextSeo {...finalProps} />
        </>
    )
}

export default MetaTags
